<mat-card>
    <img mat-card-image src="/assets/thank-you-come-again.jpg" />
    <mat-card-header>
        <mat-card-title>Order #{{lastOrder._id}}</mat-card-title>
        <mat-card-subtitle>Placed on {{lastOrder.createdAt | date:"full"}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <mat-divider></mat-divider>
        <h2>Order Summary</h2>
        <kwik-e-mart-order-summary [dataSource]="lastOrder.items"></kwik-e-mart-order-summary>
    </mat-card-content>
    <mat-card-content>
        <mat-divider></mat-divider>
        <h2>Delivery</h2>
        <table>
            <tbody>
                <tr>
                    <th scope="row">City</th>
                    <td>{{lastOrder.delivery.to.city}}</td>
                </tr>
                <tr>
                    <th scope="row">Street</th>
                    <td>{{lastOrder.delivery.to.street}}</td>
                </tr>
                <tr>
                    <th scope="row">House</th>
                    <td>{{lastOrder.delivery.to.house}}</td>
                </tr>
                <tr>
                    <th scope="row">Date</th>
                    <td>{{lastOrder.delivery.on | date:"longDate"}}</td>
                </tr>
            </tbody>
        </table>
    </mat-card-content>
    <mat-card-content>
        <mat-divider></mat-divider>
        <h2>Payment Details</h2>
        <table>
            <tbody>
                <tr>
                    <th scope="row">CC Number</th>
                    <td>{{lastOrder.payment.cc.number}}</td>
                </tr>
            </tbody>
        </table>
    </mat-card-content>
    <mat-card-footer>
        <mat-divider></mat-divider>
        <div>
            <a mat-button download [href]="lastOrder.receiptUrl">
                <mat-icon>download</mat-icon>
                Download as PDF
            </a>
            <a mat-button color="primary" [routerLink]="['..']" queryParamsHandling="preserve">
                <mat-icon>shopping_cart</mat-icon>
                Continue Shopping
            </a>
        </div>
    </mat-card-footer>
</mat-card>