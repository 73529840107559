<nav mat-tab-nav-bar>
    <a mat-tab-link *ngFor="let category of allCategories$ | async" [routerLink]="['..', category._id]"
        [active]="getCategoryId() === category._id">{{category.name}}</a>
</nav>

<div class="container">
    <div class="item" *ngFor="let product of allProductsInCategory$ | async">
        <mat-card>
            <img mat-card-image [src]="product.imageUrl" [alt]="product.name">
            <mat-card-header>
                <mat-card-title>{{product.name}}</mat-card-title>
                <mat-card-subtitle>{{product.price | currency}}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-actions>
                <button mat-icon-button color="primary" (click)="onEdit(product)">
                    <mat-icon>edit</mat-icon>
                </button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>

<button mat-fab color="primary" (click)="onEdit(null)">
    <mat-icon>add</mat-icon>
</button>