<div class="grid-list">
    <div class="grid-tile" id="welcome-image">
        <img src="/assets/kwik-e-mart.png">
    </div>
    <div class="grid-tile" id="welcome-auth">
        <router-outlet></router-outlet>
    </div>
    <div class="grid-tile" id="welcome-stats">
        <mat-card>
            <mat-list>
                <mat-list-item>
                    {{nProducts$ | async}} products available in {{nCategories$ | async}} categories.
                </mat-list-item>
                <mat-list-item>
                    {{nOrders$ | async}} orders placed so far.
                </mat-list-item>
                <mat-list-item *ngIf="!!orderService.getLastOrder()">
                    Your last order totaled {{orderService.getLastOrder().total | currency}}.
                </mat-list-item>
                <mat-list-item *ngIf="!cartService.isEmpty()">
                    You have {{cartService.getTotalQuantity()}} {{cartService.getTotalQuantity() == 1 ? 'item' : 'items'}} in the shopping cart.
                </mat-list-item>
            </mat-list>
        </mat-card>
    </div>
</div>