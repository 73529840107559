<div class="container">
    <mat-card>
        <form #f="ngForm" (ngSubmit)="onSubmit()">
            <mat-card-header>
                <mat-card-title>Welcome to Kwik-E-Mart!</mat-card-title>
                <mat-card-subtitle>Please sign in.</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field appearance="fill">
                    <mat-label>E-Mail</mat-label>
                    <input matInput type="email" name="email" autocomplete="email" required [(ngModel)]="email">
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" name="password" autocomplete="current-password" required [(ngModel)]="password">
                </mat-form-field>
            </mat-card-content>
            <mat-card-actions>
                <button mat-flat-button color="primary" type="submit" [disabled]="f.invalid">Sign In</button>
            </mat-card-actions>
        </form>
        <div class="text-center">
            <a routerLink="../signup">Don't have an account? Sign up!</a>
        </div>
    </mat-card>
</div>