<form method="post" action="/api/order" #f="ngForm" (ngSubmit)="placeOrder(f)">
    <mat-vertical-stepper linear #stepper>
        <mat-step label="Review your order.">
            <kwik-e-mart-order-summary [dataSource]="cartService.getAllItems()"></kwik-e-mart-order-summary>
            <ng-container *ngFor="let item of cartService.getAllItems(); let i = index; trackBy: productIdOfCartItem">
                <input type="hidden" name="items[{{i}}][product]" [ngModel]="item.product._id">
                <input type="hidden" name="items[{{i}}][quantity]" [ngModel]="item.quantity">
                <input type="hidden" name="items[{{i}}][purchasePrice]" [ngModel]="item.purchasePrice">
            </ng-container>
            <input type="hidden" name="total" [ngModel]="cartService.getTotalPrice().toFixed(2)">
            <div>
                <button type="button" mat-button matStepperNext>Next</button>
            </div>
        </mat-step>
        <mat-step label="Choose your preferred time and place of delivery.">
            <mat-form-field appearance="outline">
                <mat-label>Date</mat-label>
                <input matInput type="text" name="delivery[on]" ngModel required [matDatepicker]="picker" [matDatepickerFilter]="deliveryDateFilter"
                    [min]="minDeliveryDate">
                <mat-error>Please choose an available date.</mat-error>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>City</mat-label>
                <input matInput type="text" name="delivery[to][city]" [(ngModel)]="city" (cdkAutofill)="autoFill()" required [matAutocomplete]="auto">
                <mat-error>Please type a valid city name.</mat-error>
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let city of cities" [value]="city">{{city}}</mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Street</mat-label>
                <input matInput type="text" name="delivery[to][street]" [(ngModel)]="street" (cdkAutofill)="autoFill()" required>
                <mat-error>Please type a valid street name.</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>House #</mat-label>
                <input matInput type="number" name="delivery[to][house]" [(ngModel)]="house" (cdkAutofill)="autoFill()" required>
                <mat-error>Please type a valid street name.</mat-error>
            </mat-form-field>
            <div>
                <button mat-button type="button" matStepperPrevious>Back</button>
                <button mat-button type="button" matStepperNext>Next</button>
            </div>
        </mat-step>
        <mat-step label="Choose your payment method.">
            <mat-form-field appearance="outline">
                <mat-label>Card Number</mat-label>
                <input matInput type="text" name="payment[cc][number]" ngModel minlength="14" maxlength="16" pattern="^\d*$" required>
                <mat-error>Please type a valid CC number.</mat-error>
            </mat-form-field>
            <div>
                <button mat-button type="button" matStepperPrevious>Back</button>
                <button mat-button type="button" (click)="stepper.reset()">Reset</button>
            </div>
        </mat-step>
    </mat-vertical-stepper>
    <button mat-raised-button color="primary" type="submit" [disabled]="f.invalid">Place Your Order</button>
</form>

<mat-divider></mat-divider>

<a mat-button color="primary" [routerLink]="['..']" queryParamsHandling="preserve">
    <mat-icon>shopping_cart</mat-icon>
    Continue Shopping
</a>