<nav mat-tab-nav-bar>
    <a mat-tab-link *ngFor="let category of allCategories$ | async" [routerLink]="['..', category._id]" queryParamsHandling="preserve"
        [active]="getCategoryId() === category._id">{{category.name}}</a>
</nav>

<div class="container">
    <mat-card class="item" *ngFor="let product of allProductsInCategory$ | async">
        <img mat-card-image [src]="product.imageUrl" [alt]="product.name">
        <mat-card-header>
            <mat-card-title>{{product.name}}</mat-card-title>
            <mat-card-subtitle>{{product.price | currency}}</mat-card-subtitle>
        </mat-card-header>
        <div class="spacer"></div>
        <mat-card-actions>
            <button mat-icon-button color="primary" (click)="addCartItem(product)">
                <mat-icon>add_shopping_cart</mat-icon>
            </button>
        </mat-card-actions>
    </mat-card>
</div>