<!-- Main Toolbar -->
<mat-toolbar color="primary">

    <!-- Website Title -->
    <!-- <img class="cursor-pointer" src="https://storage.googleapis.com/kwik-e-mart/assets/logo.png" [alt]="title" width="64px"> -->

    <!-- Search Box -->
    <mat-form-field>
        <mat-label>Search</mat-label>
        <input matInput (input)="setFilter($event)">
    </mat-form-field>

    <span class="flex-fill"></span>

    <!-- Shopping Cart Button -->
    <button type="button" mat-icon-button [matBadge]="cartService.getTotalQuantity()" [matBadgeHidden]="cartService.isEmpty()" matBadgeColor="warn"
        (click)="sidenav.toggle()">
        <mat-icon>shopping_cart</mat-icon>
    </button>

    <!-- Logged-In User + Logout Button -->
    <ng-container *ngIf="authService.getLoggedInUser() as user">
        <button mat-button [matMenuTriggerFor]="userMenu">Hello, {{user.name.first}}!</button>
        <mat-menu #userMenu="matMenu">
            <button mat-menu-item (click)="logOut()">
                <mat-icon>logout</mat-icon>Log Out
            </button>
        </mat-menu>
    </ng-container>

</mat-toolbar>

<mat-sidenav-container>

    <!-- Shopping Cart Sidenav -->
    <mat-sidenav mode="push" position="start" fixedInViewport #sidenav>

        <button class="cart-close-button" mat-icon-button type="button" (click)="sidenav.close()">
            <mat-icon>close</mat-icon>
        </button>

        <mat-divider class="cart-divider"></mat-divider>

        <h2 *ngIf="cartService.isEmpty()">The cart is empty.</h2>

        <form (ngSubmit)="sidenav.close(); goToCheckOutAsync()">
            <ng-container *ngIf="!cartService.isEmpty()">
                <div class="cart-item" *ngFor="let item of cartService.getAllItems(); trackBy: productIdOfCartItem">
                    <mat-form-field class="quantity-field" appearance="outline">
                        <mat-label>Quantity</mat-label>
                        <input matInput type="number" [name]="item.product._id" [value]="item.quantity" min="1" #quantity
                            (input)="cartService.setItem(item.product, +quantity.value)">
                    </mat-form-field>
                    <mat-card-header class="summary">
                        <mat-card-title>{{item.product.name}}</mat-card-title>
                        <mat-card-subtitle>{{item.product.price | currency}}</mat-card-subtitle>
                        <img mat-card-avatar [src]="item.product.imageUrl" [alt]="item.product.name">
                    </mat-card-header>
                    <div class="flex-fill"></div>
                    <div class="remove-from-cart-button-wrapper">
                        <button class="remove-from-cart-button" mat-icon-button color="warn" type="button" (click)="cartService.setItem(item.product, 0)">
                            <mat-icon>remove_shopping_cart</mat-icon>
                        </button>
                    </div>
                </div>
            </ng-container>
            <mat-divider class="cart-divider"></mat-divider>
            <h2 class="cart-total">Cart Total: {{cartService.getTotalPrice() | currency}}</h2>
            <div class="cart-actions">
                <button mat-flat-button type="submit" color="primary" [disabled]="cartService.isEmpty()">
                    <mat-icon>point_of_sale</mat-icon>
                </button>
                <button mat-flat-button type="reset" color="warn" [disabled]="cartService.isEmpty()" (click)="emptyCart()">
                    <mat-icon>remove_shopping_cart</mat-icon>
                </button>
            </div>
        </form>

    </mat-sidenav>

    <mat-sidenav-content>

        <router-outlet></router-outlet>

    </mat-sidenav-content>

</mat-sidenav-container>